import { ReactComponentElement, ReactElement } from "react";
import {
    SpaceDashboardOutlined, DocumentScanner, Build
} from "@mui/icons-material";

export type ISideMenu ="/" | "/scan" | "/create-custom-bot"

export type SideMenu = {
    name: ISideMenu;
    displayName: string
    icon: any;
    isComingSoon: boolean;
}

export const allSideMenu: SideMenu[] = [
    {
        name: "/",
        displayName: "Chat with TronAI",
        icon: SpaceDashboardOutlined,
        isComingSoon: false
    },
    // {
    //     name: "/scan",
    //     displayName: "Scan Smart Contracts",
    //     icon: DocumentScanner,
    //     isComingSoon: false
    // },
    {
        name: "/create-custom-bot",
        displayName: "Build Assistant for your Dapp",
        icon: Build,
        isComingSoon: false
    }
]