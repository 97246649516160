import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SearchWidget } from 'tron-assistant-chat-widget'

function DemoBot() {
    let { botId } = useParams();

    const [botDetails, setBotDetails] = useState({
        bot_name: "Tron Assistant",
        initial_question: "Ask me anything about Tron Chain",
        logo: "https://i.ibb.co/2yMH98M/logo.png",
        loaded: false,
      })
    
      useEffect(() => {
        const getBotDetails = async () => {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": botId
            },
          };
          
          try {
                const res = await fetch(`https://api.tronassistant.xyz/bot`, requestOptions);
                const botDetails = await res.json();
            
                setBotDetails({
                    bot_name: botDetails?.data.bot_name,
                    initial_question: `Ask me anything about ${botDetails?.data.product_name ?? ''}`,
                    logo: botDetails?.data.logo ?? "https://i.ibb.co/2yMH98M/logo.png",
                    loaded: true,
                })
            } catch (e) {
                console.error(e);
                toast.error(String(e));
            }
        }
    
        if (!botDetails.loaded && botId) {
          getBotDetails();
        }
      }, [botId]);
    
      if (!botDetails.loaded) {
        return <div className='h-full w-full flex justify-center items-center bg-white-100 font-black text-2xl text-red-700'>
            We are Loading the Bot please wait...
        </div>
      }
    
    return (
        <div className='h-full w-full flex justify-center items-center bg-white-100 font-black text-2xl text-red-600'>
            <div>
                This is a demo page for the {botDetails.bot_name} Bot
            </div>
            <SearchWidget api_key={botId} />
        </div>
    )
}

export default DemoBot