import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AuthSession } from './CreateBotPage';

import {
    List,
    Avatar,
    Dialog,
    Button,
    DialogFooter,
    DialogBody,
    DialogHeader,
    Input,
} from "@material-tailwind/react";
import { IconButton } from '@mui/material';
import { ContentCopy, OpenInNew } from '@mui/icons-material';
import { toast } from 'react-toastify';

export type CustomBot = {
    bot_name: string;
    product_name: string;
    logo: string;
    api_key: string;
}

export function BotList({ bots }: { bots: CustomBot[] }) {
    return <>
        {
            bots && bots.length > 0 ?
                <List className='py-10 max-h-[85%] overflow-y-auto' >
                    {
                        bots.map((bot) => (
                            <div key={bot.bot_name} className='p-4 flex gap-4 items-center justify-between rounded-lg hover:bg-gray-50'>
                                <div className='flex gap-4 items-center'>
                                    <Avatar variant="circular" alt="candice" src={bot.logo} />
                                    <div className='flex flex-col gap-2'>
                                        <h6 className="font-bold">
                                            {bot.bot_name}
                                        </h6>
                                        <p color="gray" className="text-sm">
                                            For {bot.product_name}
                                        </p>
                                    </div>
                                </div>

                                <div className='flex items-center gap-4'>
                                    <div color="gray" className="text-sm">
                                        API Key: {bot.api_key}
                                    </div>
                                    <IconButton onClick={() => navigator.clipboard.writeText(bot.api_key ?? "")} size='small'>
                                        <ContentCopy />
                                    </IconButton>
                                </div>

                                <div>

                                    <IconButton onClick={() => window.open(`/demo/${bot.api_key}`, "__blank")}>
                                        <OpenInNew />
                                    </IconButton>
                                </div>

                            </div>
                        ))
                    }
                </List>
                : <div className='flex flex-col h-full justify-center items-center'>No bots yet!</div>
        }
    </>
}

export type CreateBotProps = {
    onClose: () => void;
}

const CreateBot = ({ onClose }: CreateBotProps) => {
    const [botName, setBotName] = useState('');
    const [productName, setProductName] = useState('');
    const [logo, setLogo] = useState('');
    const [loading, setLoading] = useState(false);

    const [files, setFiles] = useState([]);
    const [urls, setUrls] = useState([]);
    const session = useRecoilValue(AuthSession);

    const handleFileChange = (e: { target: { files: Iterable<unknown> | ArrayLike<unknown>; }; }) => {
        const uploadedFiles = Array.from(e.target.files);
        setFiles(uploadedFiles);
    };

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedUrls = [...urls];
        updatedUrls[index] = e.target.value;
        setUrls(updatedUrls);
    };

    const handleCreateBot = async () => {
        if (!botName) {
            toast.error("Please fill the bot name");
            return;
        } else if (!productName) {
            toast.error("Please fill the productName");
            return;
        } else if (!logo) {
            toast.error("Please fill the logo");
            return;
        } else if (!urls) {
            toast.error("Please add the urls");
            return;
        } else if (!files) {
            toast.error("Please add the files");
            return;
        } 
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append('files', file);
        });
        formData.append('botName', botName);
        formData.append('productName', productName);
        formData.append('logo', logo);
        formData.append('urls', urls.join(','));

        console.log('formData', formData)
        try {
            setLoading(true)
            toast.info("Creating the new Bot")
            const response = await fetch('https://api.tronassistant.xyz/bot', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
                body: formData,
            });
            const data = await response.json();
            console.log('Success uploading files:', data);
            if (data) {
                onClose()
                toast.success("Successfully created the bot.")
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            toast.error("Error while creating the bot.")
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className='p-10 w-full h-full'>
            <DialogHeader>
                Create Bot
                </DialogHeader>
            <div>
                <DialogBody>
                    <div className='flex flex-col gap-4'>
                        <Input required label='Bot Name' value={botName} onChange={(e) => setBotName(e.target.value)} crossOrigin={undefined} />
                        <Input required label='Product Name' value={productName} onChange={(e) => setProductName(e.target.value)} crossOrigin={undefined} />
                        <Input required label='Bot Logo URL' value={logo} onChange={(e) => setLogo(e.target.value)} crossOrigin={undefined} />
                    </div>
                    <p className='font-bold text-base w-full mb-2 mt-8'>Add files for training</p>
                    <input placeholder='Add files for training' className='mb-8' type="file" multiple onChange={handleFileChange} />
                    {files.map((file, index) => (
                        <div className='flex items-center' key={index}>
                            <label className='font-bold text-sm w-1/3'>
                                {file.name}
                                <span className='ml-4 font-extrabold italic'></span>
                            </label>
                            <input
                                required
                                className='outline-none border-b-[1px] border-gray-50 focus:border-gray-300 w-1/2 text-sm p-2'
                                type="text" placeholder='Add source URL' value={urls[index] || ''} onChange={(e) => handleUrlChange(e, index)} />
                        </div>
                    ))}
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={onClose}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button
                        variant="gradient"
                        color="green"
                        type='submit'
                        onClick={handleCreateBot}
                    >
                        <span>Create</span>
                    </Button>
                </DialogFooter>
            </div>
        </div>
    );
}

const AllBots: React.FC = () => {
    const session = useRecoilValue(AuthSession);
    const [botList, setBotList] = useState<CustomBot[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true)

    const onClose = () => setIsDialogOpen(false);

    useEffect(() => {
        const fetchBotList = async () => {
            setLoading(true)
            try {
                const response = await fetch(`${"https://api.tronassistant.xyz"}/bots`, {
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
                const data = await response.json();
                setBotList(data.data);
            } catch (error) {
                console.error('Error fetching bot list:', error);
            } finally {
                setLoading(false)
            }
        };

        fetchBotList();
    }, []);
    console.log(botList)

    return (
        <>
            {
                isDialogOpen ? <CreateBot onClose={onClose} /> :
                    <div className='p-10 w-full h-full'>
                        <div className='flex justify-between items-center'>
                            <p className='font-bold text-3xl'>Custom Bots</p>
                            <Button color='red' variant="gradient" onClick={() => setIsDialogOpen(true)}>
                                Create New Bot
                            </Button>
                        </div>
                        {
                            loading ? <div className='flex items-center justify-center text-red-600 font-black text-xl m-auto'>
                                We are loading the bots for you...
                            </div> :
                                <BotList bots={botList} />
                        }
                    </div>
            }
        </>
    );
};

export default AllBots;
