import { useState } from "react";
import React from "react";
import { Sidebar } from "components/SideBar";
import { ISideMenu } from "types";
import Contract from "components/Contract";
import ScanContract from "components/ScanContract";
import CreateBotPage from "components/CreateBotPage";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DemoBot from "components/DemoBot";


function App() {
  const [selectedMenu, setSelectedMenu] = useState<ISideMenu>('/')
  return (
    <div className="w-screen h-screen flex p-7 gap-x-7 ">
      <BrowserRouter basename="/">
        <Sidebar setMenu={(v) => setSelectedMenu(v)} key={selectedMenu} />
        <Routes>
          <Route path="/" Component={Contract} /> 
          <Route path="/scan" Component={ScanContract} /> 
          <Route path="/create-custom-bot" Component={CreateBotPage} /> 
          <Route path="/demo/:botId" Component={DemoBot} /> 
        </Routes>
      </BrowserRouter>

      {/* <RouterProvider router={router}/> */}
    </div>
  )
}

export default App;
