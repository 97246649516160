import { useState, useEffect } from 'react'
import { Session, createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import React from 'react'
import { atom, useRecoilState } from 'recoil'
import BotLists from './AllBots'

const supabase = createClient('https://kaspgxhdowepyxgclksf.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imthc3BneGhkb3dlcHl4Z2Nsa3NmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDA5MzcxMjksImV4cCI6MjAxNjUxMzEyOX0.vjciwsl6lgCLznTa8LYX7tVbiTWnWuSL8y9gTW05o_o')

export const AuthSession = atom<Session | null>({
    key: 'AuthSession',
    default: null,
});

export function CreateBotPage() {
    const [session, setSession] = useRecoilState(AuthSession)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
        })

        const {
        data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    return <div className="flex flex-col rounded-2xl w-full bg-white h-full shadow-lg bg-white-100 shadow-blue-gray-900/25">
            {
                session ? <BotLists /> : 
                <div className="m-auto flex flex-col items-center">
                    <Auth supabaseClient={supabase} providers={[]} appearance={{ theme: ThemeSupa }} />
                </div>
            }
    </div>
}


export default CreateBotPage;