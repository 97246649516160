import { useState, useEffect, KeyboardEvent, useRef } from "react";
import React from "react";
import { AddContracts, addedContractState } from "./AddContracts";
import { GptMessage, Message } from "./GptMessage";
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { Typography } from "@mui/material";
import { Images } from "images";
import classNames from "classnames";

const contractMessageState = atom<Message[]>({
    key: 'scanContractMessageState',
    default: [],
});

function ScanContract() {
    const [prompt, updatePrompt] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useRecoilState<Message[]>(contractMessageState);
    const [demoQuestion, setDemoQuestion] = useState<string>();
    const uploadedContracts = useRecoilValue(addedContractState);

    useEffect(() => { updatePrompt(demoQuestion) }, [demoQuestion])



    useEffect(() => {

    }, [messages]);

    const handleKeySubmit = async (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Enter") {
            return;
        }
        await handleSubmit();
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const chatHistory = messages.map(m => [m.question, m.answer]);
            const contractCode = uploadedContracts[0]?.code ?? ""

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ query: prompt, chat_history: chatHistory, contract_code: contractCode }),
            };

            setMessages([...messages, { question: prompt, answer: null, source_documents: null }])
            updatePrompt("")

            const res = await fetch("https://api-tron.gauravdhalla.in/chat", requestOptions);

            if (!res.ok) {
                throw new Error("Something went wrong");
            }

            const message = await res.json();
            console.log("message", message);
            // update the answer and source documents in last message
            setMessages(messages => {
                const newMessages = [...messages];
                newMessages[messages.length - 1] = { question: prompt, answer: message?.answer, source_documents: message?.source_documents }
                return newMessages;
            })

        } catch (err) {
            console.error(err, "err");
        } finally {
            setLoading(false);
        }
    };

    const handleClearMessage = () => {
        setMessages([])
    }

    return (
        <>
            <div className="flex flex-col rounded-2xl w-full bg-white h-full shadow-lg bg-white-100 shadow-blue-gray-900/25">
                <div className="w-full flex p-7">
                    <img src={Images.TronLogo} alt="" className="h-10 w-10 mr-5 mt-1" />
                    <div className="flex flex-col w-full bg-gray-50 p-6 rounded-b-2xl rounded-r-2xl">
                        <div>
                            <span className="text-base text-gray-400">Hi, there.</span>
                            <span className="ml-1 wave h-5">👋</span>
                        </div>
                        <div className="text-black-100 font-bold text-2xl pt-2">
                            Submit your smart contracts 📜 to get analysis 😎
                        </div>
                    </div>
                </div>
                <div className="flex flex-grow"/>
                    <div className="pt-7 px-7">
                        <div onClick={() => {}} className={classNames("w-full items-center bg-red-600 opacity-80 text-white-100 text-base text-center rounded-lg p-3 hover:opacity-75 cursor-pointer")}>
                            Submit
                        </div>
                    </div>
                <div className="w-full flex flex-col items-end self-end bottom-0 bg-white p-7">
                    {(messages.length !== 0) && <div onClick={handleClearMessage} className="text-[12px] py-2 text-gray-400 hover:text-gray-700 hover:font-black cursor-pointer">🗑️<span className="hover:underline"> Clear Previous Messages</span></div >}
                </div>
            </div>
            <AddContracts />
        </>
    );
}



export default ScanContract;
